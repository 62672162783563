/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/2/2021     bakul.banthia         Created
 *
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enUS from 'date-fns/locale/en-US';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { BrowserRouter } from 'react-router-dom';
import 'animate.css';
import './styles/main.scss';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import reportWebVitals from './reportWebVitals';
import Tessell from './Tessell';
import { store, persistor } from './redux/configureStore';
import './i18n';

LicenseInfo.setLicenseKey(
  'fdd5f552651b2a312d00e54b466024f1Tz02MTA1MSxFPTE3MDk1MTAxODU2ODgsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=',
);

ReactDOM.render(
  <React.StrictMode>
    {/* <GoogleOAuthProvider clientId="845494904126-oic4uoi34hif60557tfp8hvs5os1nl6a.apps.googleusercontent.com"> */}
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <Provider store={store}> */}
          <BrowserRouter>
            <Tessell />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </LocalizationProvider>
    {/* </GoogleOAuthProvider> */}
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
