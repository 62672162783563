/* eslint-disable no-param-reassign */

import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './configureStore';

export interface FeatureFlags {
    isServersAppAvailable: boolean;
    testBoolean: boolean;
}

const initialState: FeatureFlags = {
    isServersAppAvailable: false,
    testBoolean: false,
};

// slice
export const featureFlagsSlice = createSlice({
    name: 'featureFlags',
    initialState,
    reducers: {
        updateIsServerAvailable: (state, action: PayloadAction<any>) => {
            state.isServersAppAvailable = action.payload;
        },
    },
});

// Actions
export const featureFlagsActions = {
    // * unable to properly implement for now - will need to read up on this
    // updateIsServerAvailable: createAction(
    //     `${featureFlagsSlice.name}/updateIsServerAvailable34`,
    //     (isServersAppAvailable: boolean) => ({
    //         payload: {
    //             ...initialState,
    //             isServersAppAvailable,
    //         },
    //     }),
    // ),
    updateIsServerAvailable: featureFlagsSlice.actions.updateIsServerAvailable,
};

// Selectors
export const selectFeatureFlags = (state: RootState) => state.featureFlagsReducer;

// Reducer
export default featureFlagsSlice.reducer;
