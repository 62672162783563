import clsx from 'clsx';
import { useStyles } from './utils';

export default function TriangleWarningIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);
    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M30.5 25.0795V30.7274M30.5 34.3598H30.512M28.4542 19.1168L18.3207 35.5199C18.1117 35.8707 18.0012 36.2684 18 36.6736C17.9988 37.0787 18.1071 37.477 18.314 37.829C18.5209 38.1809 18.8192 38.4742 19.1793 38.6797C19.5394 38.8851 19.9487 38.9955 20.3665 39H40.6335C41.0513 38.9955 41.4606 38.8851 41.8207 38.6797C42.1808 38.4742 42.4791 38.1809 42.686 37.829C42.8929 37.477 43.0012 37.0787 43 36.6736C42.9988 36.2684 42.8883 35.8707 42.6793 35.5199L32.5458 19.1168C32.3326 18.7759 32.0323 18.494 31.6739 18.2984C31.3155 18.1028 30.9112 18 30.5 18C30.0888 18 29.6845 18.1028 29.3261 18.2984C28.9677 18.494 28.6674 18.7759 28.4542 19.1168Z"
                stroke="#DC6803"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
