import { getDomainUrl, getHeaders } from 'constants/urls';
import { usePost, useLazyFetch, useLazyFetchDemo, usePostDemo } from 'tools/useFetch';
import Cache from 'tools/Cache';
import DbServices from 'REST/DbServices/dbservices';
import GeneralApis from 'REST/GeneralApis/generalapis';

export const useGetActivities = () => {
    const activityRes = useLazyFetchDemo(
        `${getDomainUrl()}/event-service/notifications?userId=${encodeURIComponent(
            Cache.get('email'),
        )}&pageSize=1000`,
        { headers: getHeaders() },
        GeneralApis.getEventServiceNotificationsDetails(),
    );

    return {
        ...activityRes,
        response: activityRes.response || [],
    };
};

export const useAcknowledgeActivities = (body) => {
    const url = `${getDomainUrl()}/event-service/notifications/batch-acknowledge`;
    const activityRes = usePostDemo(
        url,
        {
            method: 'POST',
            headers: getHeaders(),
            body,
        },
        DbServices.getEmptyResponseArray,
    );

    return {
        ...activityRes,
        response: activityRes.response,
    };
};

export const useGetNotifications = () => {
    const url = `${getDomainUrl()}/event-service/notifications/summary?userId=${encodeURIComponent(
        Cache.get('email'),
    )}&pageSize=4`;
    const res = useLazyFetchDemo(
        url,
        { headers: getHeaders() },
        DbServices.getEmptyResponseObject(),
    );
    return res;
};

export const useGetNotificationCount = () => {
    const url = `${getDomainUrl()}/event-service/notifications/count?userId=${encodeURIComponent(
        Cache.get('email'),
    )}`;
    const res = useLazyFetchDemo(url, { headers: getHeaders() }, GeneralApis.getCustomCount());
    return res;
};

export const getUIBuildTimestamp = () => {
    const url = `${window.location.origin}/build.json`;
    return GeneralApis.getBuildDetails();
};
