import MockUtil from 'tools/MockUtil';
import MockRepository from 'repository/MockRepository';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

const generalApisData = MockRepository.getGeneralApisData();

const GeneralApis = {
    getEmptyResponse() {
        return MockUtil.getResponse([]);
    },
    getWhoamiDetails(headers) {
        const accessToken = headers.Authorization;
        const decodedToken = accessToken && jwt_decode(accessToken);

        return MockUtil.getResponse(generalApisData.getWhoami(decodedToken.email));
    },
    getBuildDetails() {
        return MockUtil.getResponse(generalApisData.getBuildJson());
    },
    getAppsDetails() {
        return MockUtil.getResponse(generalApisData.getAppsJson());
    },
    getTenantConfigDetails() {
        return MockUtil.getResponse(generalApisData.getTenantConfigJson());
    },
    getRolesIamUserDetails() {
        return MockUtil.getResponse(generalApisData.getRolesIamUserJson());
    },
    getCloudLocationsDetails() {
        return MockUtil.getResponse(generalApisData.getCloudLocationsJson());
    },
    getLoginDetails(body) {
        const parsedBody = JSON.parse(body);
        if (generalApisData.getLoginDetails(parsedBody.emailId)) {
            return MockUtil.getResponse(generalApisData.getLoginDetails(parsedBody.emailId));
        }
        return MockUtil.getFailedResponse(generalApisData.getLoginFailedDetails());
    },
    getComponentsTsDetails() {
        return MockUtil.getResponse(generalApisData.getComponentsTsDetails());
    },
    getApiKeysDetails() {
        return MockUtil.getResponse(generalApisData.getApiKeysDetails());
    },
    getEventServiceNotificationsDetails() {
        return MockUtil.getResponse(generalApisData.getEventServiceNotificationsDetails());
    },
    getTemplateMetadataDetails() {
        return MockUtil.getResponse(generalApisData.getTemplateMetadataJson());
    },
    getCustomCount() {
        return MockUtil.getResponse({
            count: 2,
        });
    },
};

export default GeneralApis;
