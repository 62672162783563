import axios from 'axios';

interface Config {
  [key: string]: string;
}

let config: Config | null = null;

export const getConfig = async (): Promise<Config> => {
  if (config === null) {
    const response = await axios('/config.json');
    if (!response.data) {
      throw new Error(`Unable to fetch config file: ${response.statusText}`);
    }
    config = response.data;
  }
  return config;
};
