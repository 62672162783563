// eslint-disable-next-line import/no-unresolved
import DefaultMyServicesLoader from './default/myservicesloader';
import UBSMyServicesLoader from './UBS/myservicesloader';
import MMCMyServicesLoader from './MMC/myservicesloader';
import FMRMyServicesLoader from './FMR/myservicesloader';
import { EMPTY_OBJECT } from 'constants/app.constants';
import { DATABASE_TYPE } from 'constants/db.constants';

const tenantUrl = window.location.hostname;

const getDataLoaders = () => {
  const myServicesLoader = new DefaultMyServicesLoader();

  return {
    myServicesLoader,
  };
};

const { myServicesLoader } = getDataLoaders();

export default class MyServicesData {
  // eslint-disable-next-line class-methods-use-this
  getMyServices() {
    return myServicesLoader.initMyServices();
  }

  // eslint-disable-next-line class-methods-use-this
  getComputeServices() {
    return myServicesLoader.initComputeServices();
  }

  // eslint-disable-next-line class-methods-use-this
  getFetchComputesMilvus() {
    return myServicesLoader.initFetchComputesMilvus();
  }

  // eslint-disable-next-line class-methods-use-this
  getComputeServicesIncludeTrue() {
    return myServicesLoader.initComputeServicesIncludeTrue();
  }

  // eslint-disable-next-line class-methods-use-this
  getSoftwareImages() {
    return myServicesLoader.initSoftwareImages();
  }

  // eslint-disable-next-line class-methods-use-this
  getDatabaseServices(id) {
    return myServicesLoader.initDatabaseServices(id);
  }

  // eslint-disable-next-line class-methods-use-this
  getTaskProgressServices(id) {
    return myServicesLoader.initTaskProgressServices(id);
  }

  // eslint-disable-next-line class-methods-use-this
  getTimeLineServices(id) {
    return myServicesLoader.initTimeLineForServices(id);
  }

  // eslint-disable-next-line class-methods-use-this
  getStartStopScheduleServices(id) {
    return myServicesLoader.initStartStopScheduleServices(id);
  }

  // eslint-disable-next-line class-methods-use-this
  getEligibleUsersDbServices() {
    return myServicesLoader.initEligibleUsersDbServices();
  }

  // eslint-disable-next-line class-methods-use-this
  getEligibleUsersAmServices() {
    return myServicesLoader.initEligibleUsersAmServices();
  }

  // eslint-disable-next-line class-methods-use-this
  getParameterProfileServices() {
    return myServicesLoader.initParameterProfileServices();
  }

  // eslint-disable-next-line class-methods-use-this
  getOptionsProfileServices() {
    return myServicesLoader.initOptionsProfileServices();
  }

  // eslint-disable-next-line class-methods-use-this
  getFeaturesConfigServices() {
    return myServicesLoader.initFeaturesConfigServices();
  }

  // eslint-disable-next-line class-methods-use-this
  getConnectionPoolsServices(serviceId) {
    const { engineType } = myServicesLoader.initDatabaseServices(serviceId);

    if (engineType === DATABASE_TYPE.Postgresql) {
      return myServicesLoader.initConnectionPoolsServices();
    }

    return EMPTY_OBJECT;
  }

  // eslint-disable-next-line class-methods-use-this
  getSampleDatasetsServices() {
    return myServicesLoader.initSampleDatasetsServices();
  }

  // eslint-disable-next-line class-methods-use-this
  getIntegrationTypesServices() {
    return myServicesLoader.initIntegrationTypesServices();
  }

  // eslint-disable-next-line class-methods-use-this
  getGovernanceIntegrationServices() {
    return myServicesLoader.initGovernanceIntegrationServices();
  }

  // eslint-disable-next-line class-methods-use-this
  getLogfilesServices(id) {
    return myServicesLoader.initLogfilesForServices(id);
  }

  // eslint-disable-next-line class-methods-use-this
  getStreamServices(id) {
    return myServicesLoader.initStreamForServices(id);
  }

  // eslint-disable-next-line class-methods-use-this
  getCollectionListServices(serviceId, databaseId) {
    return myServicesLoader.initCollectionListServices(serviceId, databaseId);
  }

  // eslint-disable-next-line class-methods-use-this
  getCollectionDetailServices(collectionId) {
    return myServicesLoader.initCollectionDetailServices(collectionId);
  }

  // eslint-disable-next-line class-methods-use-this
  getMonitoringServices(url, serviceId) {
    return myServicesLoader.initMonitoringServices(url, serviceId);
  }

  // eslint-disable-next-line class-methods-use-this
  getVpcDetailsMyServiceServices(vpcName) {
    return myServicesLoader.initVpcDetailsMyServiceServices(vpcName);
  }

  // eslint-disable-next-line class-methods-use-this
  getSslCredsServices() {
    return myServicesLoader.initSslCredsServices();
  }

  // eslint-disable-next-line class-methods-use-this
  postProvisionDatabase(requestBody) {
    return myServicesLoader.provisionDatabase(requestBody);
  }
}
