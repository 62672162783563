import Cache from 'tools/Cache';
import AppUtil from 'tools/AppUtil';
import SupportUtil from 'tools/SupportUtil';

export const tenantUrl = 'https://api.milvustestingvptc001.tsl-terls.cloud';
export const tenants = 'https://api.tessell.com';
const { validateEmail } = AppUtil;

// Define a mock implementation of fetch
// In local it will use the real fetch and when deployed will replace implmentation of fetch.
// if (!(window.location.hostname.includes('localhost') )) {
//   window.fetch = async (url, options) => {
//     const response = new Response(null, {
//       status: 200,
//       statusText: 'OK',
//       headers: new Headers(),
//     });

//     return response;
//   };
// }

let shouldUseNewApiUrlPattern = false;

// we are using this api to check if the control plane is on azure or not,
// if it is on azure then we will use the new api url pattern 'https://uidev008.api.tsl-terls.cloud'
// else we will use the old api url pattern 'https://api.uidev008.tsl-terls.cloud'
// if cp is on azure then then there will be a file 'cloud-type.json' with build files
// if cp is not on azure then there will be no file 'cloud-type.json' with build files
// this is a temporary solution, we will remove once we will have same url pattern for AWS as well
await fetch(`${window.location.origin}/cloud-type.json`)
  .then((response) => response.json())
  .then((json) => {
    shouldUseNewApiUrlPattern = true;
    const cloudType = json.cloud_type;
    if (cloudType?.toLowerCase() === 'azure') {
      shouldUseNewApiUrlPattern = true;
    }
  })
  .catch((err) => {});

export const getHeaders = () => {
  const headers = {
    Authorization: `Bearer ${Cache.get('idToken')}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'tenant-id': Cache.get('tenant-id'),
    // 'session-id': Cache.get('session-id'),
  };
  if (
    validateEmail(Cache.get('tenant-id')) ||
    Cache.get('tenant-id') === 'undefined'
  ) {
    Cache.clear();
    Cache.set('reload', true);
    SupportUtil.reset();
    window.location.replace(window.location.origin);
  }
  return headers;
};

export const getLoginUrl = () =>
  window.location.hostname.includes('localhost')
    ? 'http://localhost:3000'
    : window.location.origin;

export const getDomainUrl = () => {
  if (window.location.hostname.includes('localhost')) {
    return tenantUrl;
  }

  if (shouldUseNewApiUrlPattern) {
    const [tenant, ...rest] = window.location.host?.split('.') || [];
    const apiUrl = `https://${tenant}.api.${rest?.join('.')}`;
    return apiUrl;
  }
  return `https://api.${window.location.host}`;
};

export const graphQLuri = `${getDomainUrl()}/graphql`;
