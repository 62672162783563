import { v4 as uuidv4 } from 'uuid';

export function getUtcStringDifferenceInMilliseconds(utcString1, utcString2) {
    const date1 = new Date(utcString1);
    const date2 = new Date(utcString2);

    // Calculate the time difference in milliseconds
    const timeDifferenceMs = date2.getTime() - date1.getTime();

    return timeDifferenceMs;
}

export const modifyNewProvisionedService = (requestBody, obj) => {
    const newObj = JSON.parse(JSON.stringify(obj));
    const req =
        typeof requestBody === 'string'
            ? JSON.parse(requestBody)
            : JSON.parse(JSON.stringify(requestBody));

    newObj.id = uuidv4();
    newObj.name = req.name;
    newObj.description = req.description;
    newObj.dateCreated = new Date(Date.now()).toISOString();
    newObj.subscription = req.subscription;
    newObj.edition = req.edition;
    newObj.engineType = req.engineType;
    newObj.topology = req.topology;
    newObj.softwareImage = req.softwareImage;
    newObj.softwareImageVersion = req.softwareImageVersion;
    newObj.autoMinorVersionUpdate = req.autoMinorVersionUpdate;
    newObj.enableDeletionProtection = req.enableDeletionProtection;
    newObj.enableStopProtection = req.enableStopProtection;
    newObj.infrastructure = newObj.infrastructure[req.infrastructure.cloud]; // aws and azure objs
    newObj.infrastructure.computeType = req.infrastructure.computeType;
    newObj.infrastructure.availabilityZone = req.infrastructure.availabilityZone;
    newObj.infrastructure.encryptionKey = req.infrastructure.encryptionKey;
    newObj.infrastructure.region = req.infrastructure.region;
    newObj.infrastructure.vpc = req.infrastructure.vpc;
    newObj.serviceConnectivity = { ...newObj.serviceConnectivity, ...req.serviceConnectivity };
    newObj.engineConfiguration = { ...newObj.engineConfiguration, ...req.engineConfiguration };
    newObj.databases = [{ ...newObj.databases[0], ...req.databases[0] }];

    return newObj;
};

const taskProgressTypeEnum = {
    completed: 'COMPLETED',
    ongoing: 'ONGOING',
    queued: 'QUEUED',
};

export const personasEnum = {
    DATA_OWNER: 'Data Owner',
    ACCOUNT_OWNER: 'Account Owner',
};

export const graphLineType = {
    stagnant: 0,
    slowIncreasing: 0.1,
    mediumIncreasing: 1,
    fastIncreasing: 2,
};

export const stepsTimeArrayTaskProgress = [30000, 600000, 192000, 180000, 60000];

export const spikeInGraphTitles = [
    'System load average',
    'Network Usage',
    'Queries per second',
    'IO',
];

export const zeroValueTitles = ['Buffer pool utilization', 'Error counts', 'Average row lock time'];

export const taskProgressType = (type) => {
    switch (type) {
        case taskProgressTypeEnum.completed:
            return {
                status: 'Completed',
                completedTime: '2023-08-10T10:54:37.486+00:00',
            };
        case taskProgressTypeEnum.ongoing:
            return {
                status: 'Ongoing',
            };
        case taskProgressTypeEnum.queued:
            return {
                status: 'Queued',
                startTime: null,
            };
        default:
            return {};
    }
};

export const modifyTaskProgress = (id, obj) => {
    const myServicesNewlyProvisioned = JSON.parse(sessionStorage.getItem('demo-my-services'));

    const newMyServiceProvisioned = myServicesNewlyProvisioned?.filter((el) => el.id === id);
    if (myServicesNewlyProvisioned && newMyServiceProvisioned.length > 0) {
        const dateCreatedOfService = newMyServiceProvisioned[0]?.dateCreated;
        const dateCreatedServiceMs = new Date(dateCreatedOfService).getTime();

        const totalTimeMs = stepsTimeArrayTaskProgress.reduce((acc, cv) => acc + cv, 0);

        const elapsedTimeMs = Date.now() - dateCreatedServiceMs;
        const percentage =
            (elapsedTimeMs / totalTimeMs) * 100 > 100 ? 100 : (elapsedTimeMs / totalTimeMs) * 100;
        const percentCompleted = percentage.toFixed(2);
        const timeRemainingSeconds =
            totalTimeMs - elapsedTimeMs >= 0 ? (totalTimeMs - elapsedTimeMs) / 1000 : 0;

        const newObj = JSON.parse(JSON.stringify(obj));

        newObj.percentCompleted = percentCompleted;
        newObj.timeRemainingSeconds = timeRemainingSeconds;

        const startTime = dateCreatedServiceMs + stepsTimeArrayTaskProgress[0];

        newObj.steps[0].startTime = new Date(startTime).toISOString();

        let firstTaskInCompletedState = false;
        const queuedTasks = [];

        newObj.steps.forEach((_, ind) => {
            if (ind > 0) {
                const startTimeBefore =
                    new Date(newObj.steps[ind - 1].startTime).getTime() +
                    stepsTimeArrayTaskProgress[ind];
                newObj.steps[ind].startTime = new Date(startTimeBefore).toISOString();
            }

            if (ind === 0) {
                // for index 0
                if (
                    dateCreatedServiceMs + stepsTimeArrayTaskProgress[ind] <=
                    new Date(Date.now()).getTime()
                ) {
                    newObj.steps[ind] = {
                        ...newObj.steps[ind],
                        ...taskProgressType(taskProgressTypeEnum.completed),
                        ...{
                            completedTime: new Date(
                                new Date(newObj.steps[ind].startTime).getTime() +
                                    stepsTimeArrayTaskProgress[ind],
                            ).toISOString(),
                        },
                    };
                    firstTaskInCompletedState = true;
                } else {
                    newObj.steps[ind] = {
                        ...newObj.steps[ind],
                        ...taskProgressType(taskProgressTypeEnum.ongoing),
                    };
                }
            } else if (ind > 0) {
                // for index not 0
                if (
                    new Date(newObj.steps[ind - 1].startTime).getTime() +
                        stepsTimeArrayTaskProgress[ind] >=
                    Date.now()
                ) {
                    queuedTasks.push(ind);
                } else {
                    newObj.steps[ind] = {
                        ...newObj.steps[ind],
                        ...taskProgressType(taskProgressTypeEnum.completed),
                        ...{
                            completedTime: new Date(
                                new Date(newObj.steps[ind].startTime).getTime() +
                                    stepsTimeArrayTaskProgress[ind],
                            ).toISOString(),
                        },
                    };
                }
            }
        });

        if (firstTaskInCompletedState && timeRemainingSeconds > 0) {
            for (let i = 1; i < queuedTasks?.[0]; i += 1) {
                newObj.steps[i] = {
                    ...newObj.steps[i],
                    ...taskProgressType(taskProgressTypeEnum.completed),
                    ...{
                        completedTime: new Date(
                            new Date(newObj.steps[i].startTime).getTime() +
                                stepsTimeArrayTaskProgress[i],
                        ).toISOString(),
                    },
                };
            }

            queuedTasks.forEach((task, ind) => {
                if (ind === 0) {
                    newObj.steps[task] = {
                        ...newObj.steps[task],
                        ...taskProgressType(taskProgressTypeEnum.ongoing),
                    };
                } else {
                    newObj.steps[task] = {
                        ...newObj.steps[task],
                        ...taskProgressType(taskProgressTypeEnum.queued),
                    };
                }
            });
        }

        return newObj;
    }

    return obj;
};

export const valueRange = {
    '%': 80,
    count: 0.16,
    bytes: 4316442132, // 4Gib
    'MB/s': 110,
    '': 1,
    'x thousand buffers': 2001,
    'per second': 0.76,
    'per minute': 0.5,
    'bytes per second': 96,
    ms: 3,
};

export function randomPlusMinusXPercent(val, x = 15) {
    let value = val;
    // Ensure that the value is at least 0
    if (value < 0) {
        value = 0;
    }

    // Calculate the minimum and maximum bounds
    const minBound = Math.max(0, value - (x / 100) * Math.abs(value));
    const maxBound = value + (x / 100) * Math.abs(value);

    // Generate a random number within the bounds
    const result = Math.random() * (maxBound - minBound) + minBound;

    return result;
}

export function decreaseValueXPercent(initialValue, percentageDecrease) {
    // Calculate the decrease amount
    const decreaseAmount = (initialValue * percentageDecrease) / 100;

    // Ensure we don't go below 0
    const result = Math.max(0, initialValue - decreaseAmount);

    return result;
}

export function increaseValueXPercent(initialValue, percentageIncrease) {
    // Calculate the increase amount
    const increaseAmount = (initialValue * percentageIncrease) / 100;

    // Calculate the final value after increase
    const finalValue = initialValue + increaseAmount;

    return finalValue;
}

export function getRandomNumberInRange(minV, maxV) {
    let min = minV;
    let max = maxV;

    if (min >= max) {
        const temp = min;
        min = max;
        max = temp;
    }

    // Calculate the random number within the range
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

    return randomNumber;
}

export function isWeekend(date) {
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6; // 0 represents Sunday, and 6 represents Saturday
}

export function isWeekdayWorkingHours(date) {
    // Check if it's a weekday (Monday to Friday)
    const dayOfWeek = date.getDay();
    const isWeekday = dayOfWeek >= 1 && dayOfWeek <= 5; // 1-5 represent Monday to Friday

    // Check if the time is between 8:00 AM (8:00) and 5:00 PM (17:00)
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const isWorkingHours = hours >= 8 && hours < 17; // 8:00 AM to 4:59 PM

    return isWeekday && isWorkingHours;
}
