/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-escape */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/3/2021     bakul.banthia         Created
 *
 */
import React from 'react';
import { Typography } from '@mui/material';
import TessellBox from 'common-components/TessellBox';
import { getData } from 'country-list';

const publicIp = require('public-ip');

let clientIp;

export const DF = 'YYYY-MM-DD HH:mm:ss';
export const DF_DAY_DATE = 'ddd MMM Do, YYYY';
export const DF_DAY = 'MMM DD hh:mm a';
export const DF_DAY_MONTH = 'MMM DD';
export const DF_DAY_MONTH_TIME = 'MMM DD HH:mm';
export const DF_DATE = 'MMM Do, YYYY';
export const DF_MONTH = 'MMMM YYYY';
export const DF_HUMAN = 'ddd MMM Do, YYYY HH:mm:ss';
export const DF_HUMAN2 = 'ddd MMM DD, YYYY hh:mm a';
export const DF_HUMAN_LONG = 'dddd MMM Do, YYYY HH:mm:ss';
export const DF_METER = 'YYYY-MM-DDTHH:mm:ss.SSS';
export const HOURS_IN_MONTH = 730;
export const BYTES_TO_GB = 1024 * 1024 * 1024;
export const BYTES_TO_MB = 1024 * 1024;
export const BYTES_TO_KB = 1024;
export const GB_TO_TB=1024;

export const emailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

// const { getData } = require('country-list');

const countries = getData()
    .map((c) => ({
        label: c.name,
        value: c.code,
    }))
    .sort((a, b) => {
        if (a.label === 'United States of America' || b.label === 'United States of America') {
            return -1;
        }
        return a.label.localeCompare(b.label);
    });

const AppUtil = {
    getCountries() {
        return countries;
    },

    getClientIP() {
        return clientIp;
    },

    async setClientIP() {
        // const ip = await publicIp.publicIpv4();
        const ip = '182.69.183.37';
        clientIp = ip;
    },

    toTitleCase(str) {
        if (!str) {
            return false;
        }
        return str.replace(
            /\w\S*/g,
            (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
        );
    },
    getLabelValue(label, value, size, valColor) {
        const variant = size || 'body1';
        return (
            <TessellBox display="flex" flexDirection="row">
                <Typography color="textSecondary" variant={variant}>
                    {label}
                    :&nbsp;
                </Typography>
                <Typography color={valColor || 'textPrimary'} variant={variant}>
                    {value}
                </Typography>
            </TessellBox>
        );
    },
    validateEmail(email) {
        return emailRegex.test(String(email).toLowerCase());
    },

    getNumber(num, precision) {
        let p = precision || 1;
        if (precision === 0) {
            p = 0;
        }
        if (typeof num !== 'bigint') {
            num = Number(num);
        }
        return Number(num.toFixed(p));
    },

    updateUrlParams(urlParams, params) {
        Object.keys(params).forEach((k) => {
            urlParams.set(k, params[k]);
        });
        window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    },

    deleteUrlParams(urlParams, params) {
        params.forEach((p) => {
            urlParams.delete(p);
        });
        window.history.replaceState(
            {},
            '',
            urlParams?.length
                ? `${window.location.pathname}?${urlParams}`
                : `${window.location.pathname}`,
        );
    },

    checkAlphaNumeric(val) {
        const regex = /^[a-zA-Z0-9]+$/;
        return regex.test(val);
    },

    checkStartsWithAlpha(val) {
        const regex = /^[a-z]+$/;
        return regex.test(val[0]);
    },
};

export const getRegionAndAzs = (cloudLocationsList) => {
    /**
     * returns a list of regions and azs and
     * a mapping of regions and AZs
     */
    const clouds = new Set();
    const regions = new Set();
    const azs = new Set();
    const regionAzMap = {};
    if (!cloudLocationsList) {
        return {
            clouds: [],
            az: [],
            regions: [],
            regionAzMap,
        };
    }
    cloudLocationsList.forEach((cloudString) => {
        if (!cloudString) {
            return;
        }
        const splitText = cloudString.split('/');
        if (splitText) {
            clouds.add(getCloud(cloudString));
            regions.add(splitText[1]);
            if (splitText.length > 2) {
                azs.add(splitText[2]);
                if (!regionAzMap[splitText[1]]) {
                    regionAzMap[splitText[1]] = new Set();
                }
                regionAzMap[splitText[1]].add(splitText[2]);
            }
        }
    });
    const tempMap = {};
    Object.keys(regionAzMap).forEach((regionName) => {
        tempMap[regionName] = Array.from(regionAzMap[regionName]);
    });
    return {
        clouds,
        regions,
        azs,
        regionAzMap: tempMap,
    };
};

export const getCloud = (cloudString) => {
    if (cloudString) {
        const splitText = cloudString.split('/');
        if (splitText.length > 1) {
            const cloud = splitText[0];
            if (cloud.toUpperCase() === 'AWS') {
                return cloud.toUpperCase();
            }
            const titleCloud = AppUtil.toTitleCase(cloud);
            if (titleCloud === 'Azure' || titleCloud === 'Google' || titleCloud === 'Oracle') {
                return titleCloud;
            }
        }
    }
    // TODO: Update a default value
    return 'Google';
};

export default AppUtil;
