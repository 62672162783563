import { createContext, useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import Cache from 'tools/Cache';
import useInterval from 'tools/useInterval';
import { REFRESH_TOKEN_AFTER } from 'constants/app.constants';

interface UserLoginInfoProps {
  children: any;
}

export const UserLoginInfoContext = createContext({});
const SetUserLoginInfoContext = createContext((user: any) => {});

export const useUserLoginInfoContext = (): any => {
  const userLoginInfo = useContext(UserLoginInfoContext);

  return Object.keys(userLoginInfo)?.length > 0
    ? userLoginInfo
    : JSON.parse(Cache.get('loginInfo') || '{}');
};
export const useSetUserLoginInfoContext = () => {
  const setUserLoginInfo = useContext(SetUserLoginInfoContext);
  return setUserLoginInfo;
};

const refresh = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(
      currentSession.getRefreshToken(),
      (err, session) => {
        const { idToken, refreshToken, accessToken } = session;
        Cache.set('idToken', idToken?.jwtToken);
        Cache.set('refreshToken', idToken?.jwtToken);
        Cache.set('accessToken', accessToken?.jwtToken);
      },
    );
  } catch (e) {
    console.log('Unable to refresh Token', e);
  }
};

export const UserLoginInfo = (props: UserLoginInfoProps) => {
  const { children } = props;
  const [loginInfo, setLoginInfo] = useState({});
  useInterval(() => {
    refresh();
  }, REFRESH_TOKEN_AFTER);

  const setUserLoginInfo = (newLoginInfo?: any) => {
    setLoginInfo(newLoginInfo);
    Cache.set('loginInfo', JSON.stringify(newLoginInfo));
    Cache.set('userName', newLoginInfo?.username);
    if (newLoginInfo?.signInUserSession?.idToken?.jwtToken) {
      Cache.set('idToken', newLoginInfo?.signInUserSession?.idToken?.jwtToken);
      Cache.set(
        'refreshToken',
        newLoginInfo?.signInUserSession?.idToken?.jwtToken,
      );
      Cache.set(
        'accessToken',
        newLoginInfo?.signInUserSession?.accessToken?.jwtToken,
      );
    }
  };

  return (
    <SetUserLoginInfoContext.Provider value={setUserLoginInfo}>
      <UserLoginInfoContext.Provider value={loginInfo}>
        {children}
      </UserLoginInfoContext.Provider>
    </SetUserLoginInfoContext.Provider>
  );
};
