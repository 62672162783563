import MockUtil from 'tools/MockUtil';
import MockRepository from 'repository/MockRepository';

const myServicesData = MockRepository.getMyServicesData();
const availabilityMachinesData = MockRepository.getAvailabilityMachinesData();
const dataflixData = MockRepository.getDataflixData();
const scriptLibraryData = MockRepository.getScriptsLibraryData();
const benchmarksData = MockRepository.getBenchmarksData();
const serversData = MockRepository.getServersData();

const DbServices = {
    getEmptyResponseObject() {
        return MockUtil.getResponse({});
    },
    getEmptyResponseArray() {
        return MockUtil.getResponse([]);
    },
    getEmptyResponseWithMetadata() {
        return MockUtil.getResponse({
            response: [],
            metadata: null,
        });
    },
    // DB Services
    getMyServicesData() {
        return MockUtil.getResponse(myServicesData.getMyServices());
    },
    getComputeServicesData() {
        return MockUtil.getResponse(myServicesData.getComputeServices());
    },
    getFetchComputesMilvusData() {
        return MockUtil.getResponse(myServicesData.getFetchComputesMilvus());
    },
    getComputeServicesIncludeTrueData() {
        return MockUtil.getResponse(myServicesData.getComputeServicesIncludeTrue());
    },
    getSoftwareImagesData() {
        return MockUtil.getResponse(myServicesData.getSoftwareImages());
    },
    getDatabaseServicesData(id) {
        return MockUtil.getResponse(myServicesData.getDatabaseServices(id));
    },
    getTaskProgressServicesData(id) {
        return MockUtil.getResponse(myServicesData.getTaskProgressServices(id));
    },
    getTimeLineServicesData(id) {
        return MockUtil.getResponse(myServicesData.getTimeLineServices(id));
    },
    getStartStopScheduleServicesData(id) {
        return MockUtil.getResponse(myServicesData.getStartStopScheduleServices(id));
    },
    getEligibleUsersDbServicesData() {
        return MockUtil.getResponse(myServicesData.getEligibleUsersDbServices());
    },
    getEligibleUsersAmervicesData() {
        return MockUtil.getResponse(myServicesData.getEligibleUsersAmServices());
    },
    getParameterProfileServicesData() {
        return MockUtil.getResponse(myServicesData.getParameterProfileServices());
    },
    getOptionsProfileServicesData() {
        return MockUtil.getResponse(myServicesData.getOptionsProfileServices());
    },
    getFeaturesConfigServicesData() {
        return MockUtil.getResponse(myServicesData.getFeaturesConfigServices());
    },
    getConnectionPoolsServicesData(serviceId) {
        return MockUtil.getResponse(myServicesData.getConnectionPoolsServices(serviceId));
    },
    getSampleDatasetsServicesData() {
        return MockUtil.getResponse(myServicesData.getSampleDatasetsServices());
    },
    getIntegrationTypesServicesData() {
        return MockUtil.getResponse(myServicesData.getIntegrationTypesServices());
    },
    getGovernanceIntegrationServicesData() {
        return MockUtil.getResponse(myServicesData.getGovernanceIntegrationServices());
    },
    getLogfilesServicesData(id) {
        return MockUtil.getResponse(myServicesData.getLogfilesServices(id));
    },
    getStreamServicesData(id) {
        return MockUtil.getResponse(myServicesData.getStreamServices(id));
    },
    getCollectionListServicesData(serviceId, databaseId) {
        return MockUtil.getResponse(
            myServicesData.getCollectionListServices(serviceId, databaseId),
        );
    },
    getCollectionDetailServicesData(collectionId) {
        return MockUtil.getResponse(myServicesData.getCollectionDetailServices(collectionId));
    },

    getMonitoringServicesData(url, serviceId) {
        return MockUtil.getResponse(myServicesData.getMonitoringServices(url, serviceId));
    },
    getVpcDetailsMyServiceServicesData(vpcName) {
        return MockUtil.getResponse(myServicesData.getVpcDetailsMyServiceServices(vpcName));
    },
    getSslCredsServicesData() {
        return MockUtil.getResponse(myServicesData.getSslCredsServices());
    },
    postProvisionDatabaseData(requestBody) {
        return MockUtil.getResponse(myServicesData.postProvisionDatabase(requestBody));
    },
    // Availability Machines
    getAvailabilityMachinesServicesData() {
        return MockUtil.getResponse(availabilityMachinesData.getAvailabilityMachinesServices());
    },
    getAvailabilityMachinesDmmServicesData(id) {
        return MockUtil.getResponse(
            availabilityMachinesData.getAvailabilityMachinesDmmServices(id),
        );
    },
    getAvailabilityMachinesSlasServicesData() {
        return MockUtil.getResponse(availabilityMachinesData.getAvailabilityMachinesSlasServices());
    },
    getAvailabilityMachinesSnapshotsAutomatedFalseServicesData(id) {
        return MockUtil.getResponse(
            availabilityMachinesData.getAvailabilityMachinesSnapshotsAutomatedFalseServices(id),
        );
    },
    getAvailabilityMachinesSnapshotsManualFalseServicesData(id) {
        return MockUtil.getResponse(
            availabilityMachinesData.getAvailabilityMachinesSnapshotsManualFalseServices(id),
        );
    },
    getAvailabilityMachinesSlaServicesData(id) {
        return MockUtil.getResponse(
            availabilityMachinesData.getAvailabilityMachinesSlaServices(id),
        );
    },
    getAvailabilityMachinesSanitizationSchedulesServicesData(id) {
        return MockUtil.getResponse(
            availabilityMachinesData.getAvailabilityMachinesSanitizationSchedulesServices(id),
        );
    },
    getAvailabilityMachinesSanitizatiedSnapshotsServicesData(id) {
        return MockUtil.getResponse(
            availabilityMachinesData.getAvailabilityMachinesSanitizatiedSnapshotsServices(id),
        );
    },
    getAvailabilityMachinesAccessPoliciesServicesData(id) {
        return MockUtil.getResponse(
            availabilityMachinesData.getAvailabilityMachinesAccessPoliciesServices(id),
        );
    },
    getAvailabilityMachinesAllSnapshotsServicesData(id) {
        return MockUtil.getResponse(
            availabilityMachinesData.getAvailabilityMachinesAllSnapshotsServices(id),
        );
    },
    getAvailabilityMachinesBackupsServicesData(id) {
        return MockUtil.getResponse(
            availabilityMachinesData.getAvailabilityMachinesBackupsServices(id),
        );
    },
    getTaskUpdateAvailabilityMachinesData() {
        return MockUtil.getResponse(
            availabilityMachinesData.getTaskUpdateAvailabilityMachinesServices(),
        );
    },
    getTaskUpdateSanitizedCreateData() {
        return MockUtil.getResponse(
            availabilityMachinesData.getTaskUpdateSanitizedCreateServices(),
        );
    },
    getTaskUpdateBackupsCreateData() {
        return MockUtil.getResponse(availabilityMachinesData.getTaskUpdateBackupsCreateServices());
    },
    postManualSnapshotsData(requestBody, id) {
        return MockUtil.getResponse(availabilityMachinesData.postManualSnapshots(requestBody, id));
    },
    postSanitizedSnapshotsData(requestBody, id) {
        return MockUtil.getResponse(
            availabilityMachinesData.postSanitizedSnapshots(requestBody, id),
        );
    },
    postBackupsData(requestBody, id) {
        return MockUtil.getResponse(availabilityMachinesData.postBackup(requestBody, id));
    },
    postAccessPolicyData(requestBody, id) {
        return MockUtil.getResponse(availabilityMachinesData.postAccessPolicy(requestBody, id));
    },
    // Dataflix
    getDataflixData() {
        return MockUtil.getResponse(dataflixData.getDataflix());
    },
    getDataflixDetailsData(id) {
        return MockUtil.getResponse(dataflixData.getDataflixDetails(id));
    },
    getDataflixRecoveryInfoData(id, recoveryTime, snapshotId) {
        return MockUtil.getResponse(
            dataflixData.getDataflixRecoveryInfoDetails(id, recoveryTime, snapshotId),
        );
    },
    getDataflixBackupData(id) {
        return MockUtil.getResponse(dataflixData.getDataflixBackupDetails(id));
    },
    getDataflixCatalogData(id) {
        return MockUtil.getResponse(dataflixData.getDataflixCatalogDetails(id));
    },
    getDataflixSanitizedData(id) {
        return MockUtil.getResponse(dataflixData.getDataflixSanitizedDetails(id));
    },
    // Scripts Library
    getScripts() {
        return MockUtil.getResponse(scriptLibraryData.getScripts());
    },
    getVersions(id) {
        return MockUtil.getResponse(scriptLibraryData.getVersions(id));
    },
    getScriptById(id) {
        return MockUtil.getResponse(scriptLibraryData.getScriptById(id));
    },
    // Benchmarks
    getBenchmarksCatalog() {
        return MockUtil.getResponse(benchmarksData.getBenchmarksCatalog());
    },
    getBenchmarks() {
        return MockUtil.getResponse(benchmarksData.getBenchmarks());
    },
    // Servers
    getDbServersListData() {
        return MockUtil.getResponse(serversData.getDbServersListData());
    },
    getDbServerDetailData(id) {
        return MockUtil.getResponse(serversData.getDbServerDetailData(id));
    },
    getDbServersSharingEnabledData() {
        return MockUtil.getResponse(serversData.getDbServersSharingEnabledData());
    },
};

export default DbServices;
