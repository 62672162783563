import moment from 'moment-timezone';

export const DF = 'YYYY-MM-DD HH:mm:ss';
export const DF_HUMAN = 'dddd MMM Do, YYYY HH:mm:ss';

const MockUtil = {
    getFailedResponse(data, sleep) {
        return this.getPromise(data, sleep, 500);
    },

    getResponse(data, sleep) {
        return this.getPromise(data, sleep);
    },

    getPromise(data, sleep, status) {
        const CONTENT_TYPE_JSON = {
            type: 'application/json',
        };
        const STATUS_OK = {
            status: status || 200,
        };
        const timeout = sleep || 500;
        const blob = new Blob([JSON.stringify(data || {})], CONTENT_TYPE_JSON);
        const response = new Response(blob, STATUS_OK);
        const promise = new Promise((resolve) => {
            setTimeout(() => resolve(response), timeout);
        });
        return promise;
    },

    getUserData(key, id) {
        const list = sessionStorage.getItem(key);
        const dataList = list ? JSON.parse(list) : [];
        return id ? dataList.find((d) => d.id === id) : dataList;
    },

    getNumber(num, precision) {
        let p = precision || 1;
        if (precision === 0) {
            p = 0;
        }
        return Number(num.toFixed(p));
    },

    toTitleCase(str) {
        if (!str) {
            return false;
        }
        return str.replace(
            /\w\S*/g,
            (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
        );
    },
};

export default MockUtil;
