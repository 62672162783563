// eslint-disable-next-line import/no-unresolved
import DefaultNetworkLoader from './default/networksloader';
import UBSNetworkLoader from './UBS/networksloader';
import MMCNetworkLoader from './MMC/networksloader';
import FMRNetworkLoader from './FMR/networksloader';

const tenantUrl = window.location.hostname;

const getDataLoaders = () => {
  let networkLoader;

  switch (tenantUrl) {
    // case 'localhost':
    // case 'ubs.tsl-terls.cloud':
    // case 'ubs-demo.tessell.com':
    // case 'ubs-demo.azure.tessell.com':
    // case 'demo.azure.tessell.com':
    // case 'msftgsi.azure.tessell.com':
    //     networkLoader = new UBSNetworkLoader();
    //     break;
    // case 'mmc-demo.tessell.com':
    // case 'mmc.tsl-terls.cloud':
    // case 'mmc-demo.aws.tessell.com':
    // case 'demo.aws.tessell.com':
    //     networkLoader = new MMCNetworkLoader();
    //     break;
    // case 'fmr-demo.tessell.com':
    // case 'fmr.demo.tessell.com':
    // case 'fmr.tsl-terls.cloud':
    //     networkLoader = new FMRNetworkLoader();
    //     break;
    default:
      networkLoader = new DefaultNetworkLoader();
      break;
  }

  return {
    networkLoader,
  };
};

const { networkLoader } = getDataLoaders();

export default class NetworksData {
  // eslint-disable-next-line class-methods-use-this
  getVpcs() {
    return networkLoader.initVpcs();
  }

  // eslint-disable-next-line class-methods-use-this
  getByoaMetadata() {
    return networkLoader.initByoaMetadata();
  }

  // eslint-disable-next-line class-methods-use-this
  getVpcsSubscriptionName(subName) {
    return networkLoader.initVpcsSubscriptionName(subName);
  }

  // eslint-disable-next-line class-methods-use-this
  getSubnetsSubscriptionName(subName) {
    return networkLoader.initSubnetsSubscriptionName(subName);
  }

  // eslint-disable-next-line class-methods-use-this
  getVpcPeering(vpcName) {
    return networkLoader.initVpcPeering(vpcName);
  }

  // eslint-disable-next-line class-methods-use-this
  getRegisterNetworksSubscriptionName() {
    return networkLoader.initRegisterNetworksSubscriptionName();
  }

  // eslint-disable-next-line class-methods-use-this
  createVirtualNetwork(requestBody) {
    return networkLoader.createVirtualNetwork(requestBody);
  }

  // eslint-disable-next-line class-methods-use-this
  updateMaturity(_, url) {
    return networkLoader.updateMaturity(_, url);
  }

  // eslint-disable-next-line class-methods-use-this
  deleteVirtualNetwork(_, url) {
    return networkLoader.deleteVirtualNetwork(_, url);
  }
}
