/* eslint-disable no-restricted-syntax */
import dataflixJson from './dataflix.json';
import dataflixDetailsJson from './dataflixdetails.json';
import dataflixRecoveryInfoJson from './restore-info.json';
import dataflixBackupsJson from './backupstab.json';
import dataflixCatalogJson from './catalogstab.json';
import dataflixSanitizedJson from './sanitizedtab.json';
import SampleAutoSnapshot from './SampleAutoSnapshot.json';

import moment from 'moment';
import Cache from 'tools/Cache';
import replaceTimestampWithMockDate, { getDateBeforeDays } from 'tools/MockTime';
import { ACCOUNT_OWNER } from 'constants/personas.constant';

// replacing date with Mock Date
dataflixJson = replaceTimestampWithMockDate(dataflixJson);
dataflixDetailsJson = replaceTimestampWithMockDate(dataflixDetailsJson);
dataflixRecoveryInfoJson = replaceTimestampWithMockDate(dataflixRecoveryInfoJson);
dataflixBackupsJson = replaceTimestampWithMockDate(dataflixBackupsJson);
dataflixCatalogJson = replaceTimestampWithMockDate(dataflixCatalogJson);
dataflixSanitizedJson = replaceTimestampWithMockDate(dataflixSanitizedJson);

export const generateSnapshotsData = (SampleAutoSnapshot) => {
    const generatedSnapshots = [];

    // Get the current date
    const currentDate = moment();

    // Loop for each day for 35 days
    for (let i = 0; i < 35; i += 1) {
        const newObj = JSON.parse(JSON.stringify(SampleAutoSnapshot));

        const nameToUseForSnapshot = `${SampleAutoSnapshot.name} ${currentDate.format(
            'YYYY-MM-DD',
        )} ${currentDate.format('HH-mm')} UTC`;

        newObj.id = nameToUseForSnapshot;
        newObj.name = nameToUseForSnapshot;
        newObj.snapshotTime = currentDate.utc().toDate();
        const randomNumber = 9073118412800 + (Math.floor(Math.random() * 100) + 1) * 1073741824; // 8500 gib + randomNumber

        newObj.size = randomNumber;

        generatedSnapshots.push(newObj);

        // Subtract one day from the current date
        currentDate.subtract(1, 'days');
    }

    return generatedSnapshots;
};

function checkIfUserExists(users, emailId) {
    return users.some((el) => el.emailId === emailId);
}

function checkDAPAvailable(id) {
    const newAvailabilityMachinesDmm = localStorage.getItem('demo-access-policies')
        ? JSON.parse(localStorage.getItem('demo-access-policies') ?? {})
        : { [id]: [] };

    if (newAvailabilityMachinesDmm[id]) {
        newAvailabilityMachinesDmm[id] = newAvailabilityMachinesDmm[id].filter(
            (avMachine) =>
                avMachine.owner === Cache.get('email') ||
                checkIfUserExists(avMachine.sharedWith.users, Cache.get('email')),
        );

        return newAvailabilityMachinesDmm[id].length > 0 ? newAvailabilityMachinesDmm[id] : false;
    }
    return false;
}

function combineObjectsWithSameId(arr) {
    const result = {};

    for (const obj of arr) {
        const id = obj.availabilityMachineId;
        if (!result[id]) {
            result[id] = { ...obj }; // Copy the entire object
            result[id].contentInfo = { ...obj.contentInfo }; // Copy contentInfo
        } else {
            // Update contentInfo properties
            // eslint-disable-next-line no-restricted-syntax
            for (const key in obj.contentInfo) {
                if (obj.contentInfo[key] !== null && obj.contentInfo[key] !== undefined) {
                    result[id].contentInfo[key] = obj.contentInfo[key];
                }
            }
        }
    }

    return Object.values(result);
}

function doesIdExist(arrayOfObjects, targetId) {
    for (const obj of arrayOfObjects) {
        if (obj.id === targetId) {
            return true;
        }
    }
    return false;
}

export default class UBSDataflixLoader {
    // eslint-disable-next-line class-methods-use-this
    initDataflix() {
        dataflixJson.response = dataflixJson.response.filter((el) => {
            const newAvailabilityMachinesDmm = checkDAPAvailable(el.availabilityMachineId);

            // checking and filtering if DAP is not available for the user.
            return (
                Cache.get('persona') === ACCOUNT_OWNER ||
                newAvailabilityMachinesDmm ||
                el.owner === Cache.get('email') ||
                el?.sharedWith?.users?.includes(Cache.get('email')) // if added in sharedwith users
            );
        });
        return dataflixJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initDataflixDetails(id) {
        return dataflixDetailsJson[id];
    }

    // eslint-disable-next-line class-methods-use-this
    initDataflixRecoveryInfo(id, recoveryTime, snapshotId) {
        const dataflixDataJson = dataflixRecoveryInfoJson[id];

        if (snapshotId) {
            dataflixDataJson.snapshotId = snapshotId;
        }

        if (recoveryTime) {
            dataflixDataJson.recoveryTime = new Date(recoveryTime).toISOString();
        }

        return dataflixDataJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initDataflixBackup(id) {
        const newAvailabilityMachinesDmm = checkDAPAvailable(id);

        const dataflixBackupsData = dataflixBackupsJson[id];

        if (
            newAvailabilityMachinesDmm &&
            newAvailabilityMachinesDmm.some((el) => el.owner !== Cache.get('email'))
        ) {
            const allAvMachinesSameUser = newAvailabilityMachinesDmm.filter((el) =>
                checkIfUserExists(el.sharedWith.users, Cache.get('email')),
            );
            // Combined objects are having policies with same id,
            // having contentInfo common so that multiple things can be shared i.e backups, asis, sanitized snapshots
            const combinedObjects = combineObjectsWithSameId(allAvMachinesSameUser);

            if (!combinedObjects[0].contentInfo.backupContent) {
                return {
                    ...dataflixBackupsJson[id],
                    backupCatalog: [],
                };
            }
            if (combinedObjects[0].contentInfo.backupContent.automated) {
                dataflixBackupsData.backupCatalog = dataflixBackupsData.backupCatalog.filter(
                    (el) => el.manual === false,
                );
                return dataflixBackupsData;
            }

            if (combinedObjects[0].contentInfo.backupContent.manual) {
                dataflixBackupsData.backupCatalog = dataflixBackupsData.backupCatalog.filter(
                    (el) =>
                        el.manual === true &&
                        doesIdExist(combinedObjects[0].contentInfo.backupContent.manual, el.id),
                );
                return dataflixBackupsData;
            }
        }

        return dataflixBackupsData;
    }

    // eslint-disable-next-line class-methods-use-this
    initDataflixCatalog(id) {
        let dataFlixCatalogData = replaceTimestampWithMockDate(dataflixCatalogJson[id]);

        // Setting PITR catalog time range
        const pitrCatalogTimeRangeObj =
            dataflixCatalogJson[id]?.pitrCatalog?.[0]?.regions?.[0]?.timeRanges?.[0] ?? {};

        pitrCatalogTimeRangeObj.toTime = new Date();
        pitrCatalogTimeRangeObj.fromTime = getDateBeforeDays(15);

        const newAvailabilityMachinesDmm = checkDAPAvailable(id);

        // for generating automatic snapshots.
        const generatedSnapshots = generateSnapshotsData(SampleAutoSnapshot);

        if (
            newAvailabilityMachinesDmm &&
            newAvailabilityMachinesDmm.some((el) => el.owner !== Cache.get('email'))
        ) {
            const allAvMachinesSameUser = newAvailabilityMachinesDmm.filter((el) =>
                checkIfUserExists(el.sharedWith.users, Cache.get('email')),
            );
            // Combined objects are having policies with same id,
            // having contentInfo common so that multiple things can be shared i.e backups, asis, sanitized snapshots
            const combinedObjects = combineObjectsWithSameId(allAvMachinesSameUser);

            if (!combinedObjects[0].contentInfo.asIsContent) {
                return {
                    ...dataFlixCatalogData,
                    snapshotCatalog: [],
                    pitrCatalog: [],
                };
            }

            if (combinedObjects[0].contentInfo.asIsContent.automated) {
                dataFlixCatalogData.snapshotCatalog = [
                    ...dataFlixCatalogData.snapshotCatalog.filter((el) => el.manual === false),
                    ...generatedSnapshots,
                ];
                return dataFlixCatalogData;
            }

            if (combinedObjects[0].contentInfo.asIsContent.manual) {
                dataFlixCatalogData.snapshotCatalog = dataFlixCatalogData.snapshotCatalog.filter(
                    (el) =>
                        el.manual === true &&
                        doesIdExist(combinedObjects[0].contentInfo.asIsContent.manual, el.id),
                );
                return dataFlixCatalogData;
            }
        }

        if (dataFlixCatalogData && generatedSnapshots) {
            // add generatedSnapshots to response.
            dataFlixCatalogData = {
                ...dataFlixCatalogData,
                snapshotCatalog: [...dataFlixCatalogData.snapshotCatalog, ...generatedSnapshots],
            };
        }

        return dataFlixCatalogData;
    }

    // eslint-disable-next-line class-methods-use-this
    initDataflixSanitized(id) {
        const newAvailabilityMachinesDmm = checkDAPAvailable(id);
        const dataflixSanitizedData = dataflixSanitizedJson[id];
        if (
            newAvailabilityMachinesDmm &&
            newAvailabilityMachinesDmm.some((el) => el.owner !== Cache.get('email'))
        ) {
            const allAvMachinesSameUser = newAvailabilityMachinesDmm.filter((el) =>
                checkIfUserExists(el.sharedWith.users, Cache.get('email')),
            );
            // Combined objects are having policies with same id,
            // having contentInfo common so that multiple things can be shared i.e backups, asis, sanitized snapshots
            const combinedObjects = combineObjectsWithSameId(allAvMachinesSameUser);

            if (!combinedObjects[0].contentInfo.sanitizedContent) {
                return {
                    ...dataflixSanitizedJson[id],
                    snapshotCatalog: [],
                };
            }

            // if only automated and not manual
            if (
                combinedObjects[0].contentInfo.sanitizedContent.automated &&
                !combinedObjects[0].contentInfo.sanitizedContent.manual
            ) {
                dataflixSanitizedData.snapshotCatalog =
                    dataflixSanitizedData.snapshotCatalog.filter((el) => el.manual === false);
                return dataflixSanitizedData;
            }

            // it will handle automated and not manual
            if (combinedObjects[0].contentInfo.sanitizedContent.manual) {
                dataflixSanitizedData.snapshotCatalog =
                    dataflixSanitizedData.snapshotCatalog.filter((el) =>
                        doesIdExist(combinedObjects[0].contentInfo.sanitizedContent.manual, el.id),
                    );
                return dataflixSanitizedData;
            }
        }
        return dataflixSanitizedJson[id];
    }
}
