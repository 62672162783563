import { createContext, useContext, useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
} from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyDP_V20kTpf1U4pkqqcmKvsi5A9egrZjoQ',
  authDomain: 'thermal-hour-337716.firebaseapp.com',
  projectId: 'thermal-hour-337716',
  storageBucket: 'thermal-hour-337716.appspot.com',
  messagingSenderId: '939850463000',
  appId: '1:939850463000:web:ac73067a4f39fa0caf106d',
};

export const NetworkConnectionContext = createContext<{
  db: any;
  auth: any;
  functions: any;
  fetchData: any;
  fetchRowData: any;
}>(null);

export const GCPNetworkConnection = (props) => {
  const { children } = props;

  const app = initializeApp(firebaseConfig);
  // Initialize Firestore

  const db = getFirestore(app, 'tessell');

  const auth = getAuth(app);
  const functions = getFunctions(app, 'us-east1');

  const fetchData = async (collectionName) => {
    const querySnapshot = await getDocs(collection(db, collectionName));
    const dataList = querySnapshot.docs.map((doc) => doc.data());
    return dataList;
  };

  const fetchRowData = async (collectionName, id) => {
    const querySnapshot = await getDoc(doc(db, collectionName, id));
    return querySnapshot.data();
  };

  return (
    <NetworkConnectionContext.Provider
      value={{
        db: db,
        auth: auth,
        functions: functions,
        fetchData: fetchData,
        fetchRowData: fetchRowData,
      }}
    >
      {children}
    </NetworkConnectionContext.Provider>
  );
};
