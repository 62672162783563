/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-shadow */
/*
 * Copyright © Tessell Inc, 2021. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { AlertColor, Snackbar } from '@mui/material';
import { Alert } from '@mui/lab';
import PropTypes from 'prop-types';

interface NotificationProps {
    message: string;
    notify?: boolean;
    setNotify?: (args: boolean) => void;
    severity: AlertColor;
    onClose?: (args: any) => void;
}

export default function Notification(props: NotificationProps) {
    const { message, notify, setNotify, severity = 'info', onClose } = props;

    const [duration, setDuration] = useState(6000);

    useEffect(() => {
        if (notify) {
            if (message?.length > 100) {
                setDuration(10000);
            } else {
                setDuration(5000);
            }
        }
    }, [notify, message]);

    return message?.length ? (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={notify}
            autoHideDuration={duration}
            onClose={(ev, reason) => {
                if (reason === 'timeout' || reason === 'escapeKeyDown') {
                    onClose?.({ message, severity });
                    setNotify?.(false);
                }
            }}
            key={message}
        >
            <Alert
                severity={severity}
                onClose={() => {
                    onClose?.({ message, severity });
                    setNotify?.(false);
                }}
            >
                {message}
            </Alert>
        </Snackbar>
    ) : null;
}
