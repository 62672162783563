import MockUtil from 'tools/MockUtil';
import MockRepository from 'repository/MockRepository';

const billingData = MockRepository.getBillingData();

const Billing = {
    getRatesDetails() {
        return MockUtil.getResponse(billingData.getRates());
    },

    getTotalCreditsDetails() {
        return MockUtil.getResponse(billingData.getTotalCredits());
    },

    getMetersResDetails(start, end) {
        return MockUtil.getResponse(billingData.getMetersRes(start, end));
    },

    getMetersResShortDurationDetails(start, end, dbId, cycle) {
        return MockUtil.getResponse(billingData.getMetersResShortDuration(start, end, dbId, cycle));
    },

    getDefaultPaymentMethodDetails() {
        return MockUtil.getResponse(billingData.getDefaultPaymentMethod(), 0);
    },

    getBillsSystemGeneratedTrueDetails() {
        return MockUtil.getResponse(billingData.getBillsSystemGeneratedTrue(), 0);
    },

    getBillingProfileDetails() {
        return MockUtil.getResponse(billingData.getBillingProfile());
    },

    getInvoicesDetails() {
        return MockUtil.getResponse(billingData.getInvoices());
    },
};

export default Billing;
