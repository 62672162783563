/* eslint-disable react/destructuring-assignment */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/7/2021     bakul.banthia         Created
 *
 */

import React from 'react';
import { CircularProgress, Box } from '@mui/material';

// export type LoadingScreenPropType = {
//     isLoading: boolean,
//     children: any,
// }

export default function LoadingOverlay(props) {
    const { isLoading, children, containerStyles } = props;
    return (
        <Box position="relative" height="100%" width="100%" sx={containerStyles}>
            {isLoading && (
                <Box
                    position="absolute"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    top="50%"
                    right="50%"
                >
                    <CircularProgress style={props.loadingColor ? { color: '#fff' } : {}} />
                </Box>
            )}
            {children}
        </Box>
    );
}
