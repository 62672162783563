/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/19/2021     bakul.banthia         Created
 *
 */
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './utils';

export default function ChevronRight(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="20"
            height="20"
            viewBox="3 1 12 14"
            xmlSpace="preserve"
            fill="none"
            // xversion="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                d="M7.44971 4.79993L10.4774 7.82763C10.501 7.84973 10.5197 7.87642 10.5326 7.90605C10.5454 7.93569 10.552 7.96764 10.552 7.99993C10.552 8.03223 10.5454 8.06418 10.5326 8.09381C10.5197 8.12345 10.501 8.15014 10.4774 8.17224L7.44971 11.1999"
                stroke="#666666"
            />
        </svg>
    );
}
