/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     10/13/2021     bakul.banthia         Created
 *
 */

import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { forgotPassword } from 'api-clients/loginServices';
import AppUtil from 'tools/AppUtil';
import TessellInput from 'common-components/TessellInput';

export default function ForgotPasswordDialog(props) {
    const { validateEmail } = AppUtil;
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const { show, setShow, setMessage, setNotify, setSeverity } = props;
    return (
        <Dialog
            open={show}
            onClose={() => {
                setShow(false);
                setLoading(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Forgot Password</DialogTitle>

            <DialogContent sx={{ minWidth: '400px' }}>
                <TessellInput
                    autoFocus
                    label="Email"
                    size="small"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Stack width="100%" direction="row" justifyContent="flex-start" mt={2}>
                    <Stack spacing={1} direction="row" justifyContent="flex-end" width="100%">
                        <Button
                            onClick={() => {
                                setShow(false);
                                setLoading(false);
                            }}
                            variant="outlined"
                            size="small"
                            width={80}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            onClick={() => {
                                setLoading(true);
                                forgotPassword(email).then((res) => {
                                    res.json().then((response) => {
                                        if (res.status === 200) {
                                            setSeverity('success');
                                        } else {
                                            setSeverity('error');
                                        }
                                        setMessage(response?.message);
                                        setNotify(true);
                                        setLoading(false);
                                        setShow(false);
                                    });
                                });
                            }}
                            color="primary"
                            width={80}
                            size="small"
                            variant="contained"
                            loading={loading}
                            disabled={!email?.length || !validateEmail(email)}
                        >
                            Send Reset Link
                        </LoadingButton>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}
