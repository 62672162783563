import MockUtil from 'tools/MockUtil';
import MockRepository from 'repository/MockRepository';

const iamData = MockRepository.getIamData();

const Iam = {
    getPersonasData() {
        return MockUtil.getResponse(iamData.getPersonas());
    },
    getUsersData() {
        return MockUtil.getResponse(iamData.getUsers());
    },
    getUpgradablePersonasData() {
        return MockUtil.getResponse(iamData.getUpgradablePersonas());
    },
    getSsoData() {
        return MockUtil.getResponse(iamData.getSso());
    },
    createPersona(reqBody) {
        return MockUtil.getResponse(iamData.createPersona(reqBody));
    },
    createUser(reqBody) {
        return MockUtil.getResponse(iamData.createUser(reqBody));
    },
};

export default Iam;
