import subscriptionJson from './subscriptions.json';
import subscriptionUserJson from './subscriptionuser.json';
import subscriptionRegionJson from './subscriotionregions.json';
import subscriptionAsConsumer from './subscriptions-as-consumer.json';
import SampleSubscription from './SampleSubscription.json';
import replaceTimestampWithMockDate from 'tools/MockTime';

// replacing date with Mock Date
subscriptionJson = replaceTimestampWithMockDate(subscriptionJson);
subscriptionUserJson = replaceTimestampWithMockDate(subscriptionUserJson);
subscriptionRegionJson = replaceTimestampWithMockDate(subscriptionRegionJson);
subscriptionAsConsumer = replaceTimestampWithMockDate(subscriptionAsConsumer);
SampleSubscription = replaceTimestampWithMockDate(SampleSubscription);

const addAssociationCloudTypes = (requestBody, sampleSubscription) => {
  const newObj = [];
  const allRegionsMap = {};

  subscriptionRegionJson.response.forEach((region) => {
    allRegionsMap[region.region] = region.name;
  });

  if (Object.keys(requestBody.cloudConfigs).length > 0) {
    newObj.push({
      ...sampleSubscription.associatedCloudTypes[0],
      cloudType: Object.keys(requestBody.cloudConfigs)?.[0].toUpperCase(),
      regions: requestBody.cloudConfigs[
        Object.keys(requestBody.cloudConfigs)?.[0]
      ]?.regions?.map((region) => ({
        name: allRegionsMap[region],
        region,
      })),
    });
  }

  if (Object.keys(requestBody.cloudConfigs).length > 1) {
    newObj.push({
      ...sampleSubscription.associatedCloudTypes[0],
      cloudType: Object.keys(requestBody.cloudConfigs)?.[1].toUpperCase(),
      regions: requestBody.cloudConfigs[
        Object.keys(requestBody.cloudConfigs)?.[1]
      ]?.regions?.map((region) => ({
        name: allRegionsMap[region],
        region,
      })),
    });
  }

  return newObj;
};

const modifyNewSubscription = (requestBody, sampleSubscription) => {
  const newObj = sampleSubscription;

  newObj.name = requestBody.name;
  newObj.description = requestBody.description;
  newObj.deploymentType = requestBody.deploymentType;
  newObj.dateCreated = new Date().toISOString();

  newObj.associatedCloudTypes = addAssociationCloudTypes(
    requestBody,
    sampleSubscription,
  );

  return newObj;
};
export default class DefaultSubscriptionLoader {
  // eslint-disable-next-line class-methods-use-this
  initSubscriptions() {
    const subscriptionsNewlyProvisioned = JSON.parse(
      sessionStorage.getItem('demo-subscriptions'),
    );

    if (!subscriptionsNewlyProvisioned) {
      return [];
    }

    const newSubscriptionJson = {
      response: [...subscriptionsNewlyProvisioned],
    };

    return newSubscriptionJson;
  }

  // eslint-disable-next-line class-methods-use-this
  initSubscriptionUser() {
    return subscriptionUserJson;
  }

  // eslint-disable-next-line class-methods-use-this
  initSubscriptionRegions() {
    return subscriptionRegionJson;
  }

  // eslint-disable-next-line class-methods-use-this
  initSubscriptionAsConsumer() {
    return subscriptionAsConsumer;
  }

  // eslint-disable-next-line class-methods-use-this
  createSubscription(requestBody) {
    const newSubscription = modifyNewSubscription(
      JSON.parse(requestBody),
      SampleSubscription,
    );

    if (sessionStorage.getItem('demo-subscriptions')) {
      const oldSubscription = JSON.parse(
        sessionStorage.getItem('demo-subscriptions'),
      );
      sessionStorage.setItem(
        'demo-subscriptions',
        JSON.stringify([...oldSubscription, newSubscription]),
      );
    } else {
      sessionStorage.setItem(
        'demo-subscriptions',
        JSON.stringify([newSubscription]),
      );
    }
    return newSubscription;
  }

  // eslint-disable-next-line class-methods-use-this
  updateSubscription(subName, cloud, action) {
    const oldSubscription = JSON.parse(
      sessionStorage.getItem('demo-subscriptions'),
    );

    if (action === 'disable') {
      oldSubscription.forEach((subs, indx) => {
        if (subs.name === subName) {
          oldSubscription[indx].associatedCloudTypes = oldSubscription[
            indx
          ].associatedCloudTypes.filter(
            (el) => el.cloudType !== cloud.toUpperCase(),
          );
        }
      });
    }
    sessionStorage.setItem(
      'demo-subscriptions',
      JSON.stringify(oldSubscription),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  updateSubscriptionToEnable(name, body) {
    let objToReturn = [];
    const oldSubscription = JSON.parse(
      sessionStorage.getItem('demo-subscriptions'),
    );

    const reqBody = JSON.parse(body);

    const allRegionsMap = {};

    subscriptionRegionJson.response.forEach((region) => {
      allRegionsMap[region.region] = region.name;
    });

    oldSubscription.forEach((subs, indx) => {
      if (subs.name === name) {
        oldSubscription[indx].associatedCloudTypes.push({
          cloudType: Object.keys(reqBody)[0].toUpperCase(),
          associationStatus: 'ACTIVE',
          associatingRegions: [],
          regions: Object.values(reqBody)[0].regions.map((region) => ({
            name: allRegionsMap[region],
            region,
          })),
        });
        objToReturn = oldSubscription[indx];
      }
    });

    sessionStorage.setItem(
      'demo-subscriptions',
      JSON.stringify(oldSubscription),
    );

    return objToReturn;
  }

  // eslint-disable-next-line class-methods-use-this
  updateSubscriptionRegions(name, cloud, body) {
    const oldSubscription = JSON.parse(
      sessionStorage.getItem('demo-subscriptions'),
    );
    let objToReturn = [];
    const reqBody = JSON.parse(body);

    const allRegionsMap = {};

    subscriptionRegionJson.response.forEach((region) => {
      allRegionsMap[region.region] = region.name;
    });

    oldSubscription.forEach((subs, indx) => {
      if (subs.name === name) {
        oldSubscription[indx].associatedCloudTypes.forEach((el, idx) => {
          if (el.cloudType === cloud) {
            oldSubscription[indx].associatedCloudTypes[idx].regions = reqBody[
              Object.keys(reqBody)[0]
            ]?.map((region) => ({
              name: allRegionsMap[region],
              region,
            }));
          }
        });
        objToReturn = oldSubscription[indx];
      }
    });

    sessionStorage.setItem(
      'demo-subscriptions',
      JSON.stringify(oldSubscription),
    );
    return objToReturn;
  }
}
