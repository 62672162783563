import {
    decreaseValueXPercent,
    getRandomNumberInRange,
    getUtcStringDifferenceInMilliseconds,
    graphLineType,
    increaseValueXPercent,
    isWeekdayWorkingHours,
    isWeekend,
    personasEnum,
    randomPlusMinusXPercent,
    spikeInGraphTitles,
    valueRange,
    zeroValueTitles,
} from './myserviceutil';
import Cache from 'tools/Cache';

type GetMonitoringData = {
    customURL: string;
    MetricsJsonWithTime: any;
    serviceId: string;
    MyServicesJson: any;
};

export const getMonitoringData = (props: GetMonitoringData) => {
    const { customURL, MetricsJsonWithTime, serviceId, MyServicesJson } = props;
    const isServiceDataOwner = MyServicesJson[personasEnum.DATA_OWNER].response.some(
        (el) => el.id === serviceId,
    );

    const startTime = customURL.split('start-time=')[1].split('&end-time=')[0];
    const endTime = customURL.split('start-time=')[1].split('&end-time=')[1].split('&step=')[0];
    const differenceInMilliseconds = getUtcStringDifferenceInMilliseconds(startTime, endTime);

    const metricsWithJsonArr = MetricsJsonWithTime;
    const timeNow = Date.now();
    const startTimeCal = timeNow - differenceInMilliseconds;

    let diffInTime = 30000;

    if (differenceInMilliseconds <= 3600000) {
        diffInTime = 90000;
    } else if (differenceInMilliseconds <= 21600000) {
        diffInTime = 270000;
    } else if (differenceInMilliseconds <= 43200000) {
        diffInTime = 7000000;
    } else if (differenceInMilliseconds <= 86400000) {
        diffInTime = 7000000;
    } else {
        diffInTime = 14000000;
    }

    metricsWithJsonArr.forEach((metricEl, ind) => {
        // get standard range value
        let val = valueRange[metricsWithJsonArr[ind].unit];
        const intermitentValue = val;

        metricEl.metrics.forEach((el, idx) => {
            const generatedArr = [];
            let timeNow = Date.now();
            // if want to start spikes from one point put 0 instead of random
            let spikeTimer = getRandomNumberInRange(0, 2);
            let randomNumber = val;
            // it will go every diffInTime back till it gets startTime (deduced from API)
            while (timeNow >= startTimeCal) {
                if (el.displayName === 'Availability') {
                    randomNumber = 1;
                } else if (metricEl.title === 'Memory') {
                    randomNumber = randomPlusMinusXPercent(
                        randomNumber,
                        graphLineType.slowIncreasing,
                    );
                } else if (spikeInGraphTitles.includes(metricEl.title)) {
                    // for randomly increasing value to show SPIKE in graph
                    if (spikeTimer % 4 === 0) {
                        randomNumber = increaseValueXPercent(val, getRandomNumberInRange(20, 40));
                    } else randomNumber = randomPlusMinusXPercent(val, 10);
                } else if (
                    metricEl.title.includes('Filesystem') ||
                    metricEl.title.includes('Aborted')
                ) {
                    randomNumber = decreaseValueXPercent(val, graphLineType.stagnant);
                } else if (zeroValueTitles.includes(metricEl.title)) {
                    randomNumber = decreaseValueXPercent(0, graphLineType.stagnant);
                } else {
                    randomNumber = randomPlusMinusXPercent(val, 1);
                }

                // this changes monitoring data for data owner account and on weekend.
                if (
                    isServiceDataOwner &&
                    (isWeekend(new Date(timeNow)) || !isWeekdayWorkingHours(new Date(timeNow)))
                ) {
                    randomNumber = 0;
                }

                const newArray = [timeNow / 1000, randomNumber];
                generatedArr.push(newArray);
                timeNow -= diffInTime / 2;
                spikeTimer += 1;
            }

            // setting metricValues with generated array
            metricsWithJsonArr[ind].metrics[idx].metricValues = generatedArr;

            // how much next color should start from.
            val = decreaseValueXPercent(val, getRandomNumberInRange(10, 15));
        });
    });

    return metricsWithJsonArr;
};
