import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFeatureFlags, featureFlagsActions } from 'redux/featureFlags.slice';

export type FeatureFlagsOperators = {
    serversApp: {
        isServersAppAvailable: boolean;
        updateIsServersAppAvailable: (arg: boolean) => void;
    };
};

// ? whoami api call gives feature flag for isServersAppAvailable - key = 'oracle-multi-service-provisioning'

const useFeatureFlags = (): FeatureFlagsOperators => {
    const dispatch = useDispatch();

    const featureFlags = useSelector(selectFeatureFlags);

    const updateIsServersAppAvailable = (isServersAppAvailableArg: boolean) => {
        dispatch(featureFlagsActions.updateIsServerAvailable(isServersAppAvailableArg));
    };

    return {
        serversApp: {
            isServersAppAvailable: featureFlags?.isServersAppAvailable,
            updateIsServersAppAvailable,
        },
    };
};

export default useFeatureFlags;
